.Footer {
  background-color: rgb(21, 31, 40);
  min-height: 6vh;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
}

.logofooter {
  background-color: rgb(21, 31, 40);
  height: 9vh;
  width: 43vh;
  padding: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.diversity {
  font-size: calc(4px + 1vmin);
  color: rgb(84, 110, 122);
}

.linea {
  background-color: rgb(0, 0, 0);
}

.unity {
  font-size: calc(10px + 1vmin);
  color: rgb(84, 110, 122);
  margin: 6px;
}
