.Header {
    background-color:  white;
    height: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin);
    color: white;
  }

  .logo {
    height: 15vh;
    display: flex;
    flex-direction: column;
    
  }
 
  .ui.teal.buttons{
    background-color: rgb(84, 110, 122);
  }

  .teal{
    background-color: rgb(84, 110, 122);
    
  }
  
  .registroUsuario{
    display: block !important;
    color: rgb(84, 110, 122);
    margin-top: 5px;
  }
  .ui.teal.button:focus,
  .ui.teal.buttons .button:focus {
    background-color: rgb(84, 110, 122);
  }

  .teal.button:focus,
  .teal.buttons .button:focus {
    background-color: rgb(84, 110, 122);
  }