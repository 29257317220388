img {
  max-width: 1465px;
  max-height: 675px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Inicio {
  background-color: rgb(255, 255, 255);

  display: flexbox;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ImagenInicio {
  display: flex;
}
