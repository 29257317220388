.StepsYo{
  background-color: #ffffff;
  padding: 1px;
  min-height: 6vh;
  min-width: calc (700px + 2vh);
  display: none;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  font-size: calc(6px + 1vh);
  color: rgb(84, 110, 122);
}

.StepsEl{
  background-color: #ffffff;
  padding: 1px;
  min-height: 6vh;
  min-width: calc (700px + 2vh);
  display: none;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  font-size: calc(6px + 1vh);
  color: rgb(84, 110, 122);
}

.StepsInfo{
  padding: 1px;
  align-items: center;
  text-align: justify;
  width: 100%;
  display: flex;
  flex-direction: column;
  
 
}

.Brain{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;

}
.Steps{
  background-color: #ffffff;
  padding: 10px;
  min-height: 25vh;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(6px + 1vh);
  color: rgb(84, 110, 122);
  
}
.Psicocard{

  padding: 10px;

}

