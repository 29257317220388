.Stiki {
  height: 4vh;
  display: flex;
}

.homes {
  background-color: #f2f2fc;
  min-height: 85vh;
  display: flex;
}

.button-container {
  background-color: #f2f2fc;
  min-height: 75vh;
  display: flex;
}
.wrapper svg {
  height: 100%;
  width: 100%;
}
.button-container t-1 {
  position: absolute;
  bottom: 6em;
  left: 2em;
  box-align: center;
  color: #3a70b1;
  font-size: 18px;
}
.container-segment {
  position: absolute;
  bottom: 14.7em;
  left: 6.5em;
  box-align: center;
  color: #2c2e2f;
  font-size: 18px;
}
.container-segment-titulo {
  color: #3a70b1;
  font-size: 22px;
}

.container-segment-titulosecundario {
  color: #152d4b;
  font-size: 25px;
}

.container-segment-titulosecundario2 {
  color: #5b636d;
  font-size: 20px;
}
.container-segment2 {
  bottom: 0em;
  left: 15em;
}

.button-container a {
  position: absolute;
  bottom: 17em;
  right: 45em;
  box-align: center;
  border-radius: 0.2em;
  border: solid 2px #ffffff;
  color: #f2f2fc;
  text-size-adjust: 14px;
  text-align: center;
  padding: 0.6em 1em;
  display: inline-block;
  cursor: pointer;
}

.button-container des {
  position: absolute;
  bottom: 18em;
  right: 45em;
  box-align: center;
  border-radius: 0.2em;

  color: #73a2c1;
  text-size-adjust: 14px;
  text-align: center;
  padding: 0.6em 1em;
  display: inline-block;
  cursor: pointer;
  background-color: #f2f2fc;
}

@media only screen and (max-width: 600px) {
  .button-container {
    background-color: #f2f2fc;
    min-height: 80vh;
    display: flex;
  }
  .button-container a {
    position: absolute;
    bottom: 5em;
    right: 7.5em;
    box-align: center;
    border-radius: 0.2em;
    border: solid 2px #f2f2fc;
    color: #ffffff;
    background-color: #90d18a;
    text-size-adjust: 14px;
    text-align: center;
    padding: 0.6em 1em;

    cursor: pointer;
  }
}

.page-title {
  display: block !important;
  text-align: center;
  font-size: 2rem;
  margin-top: 5px;
}

.form-container {
  width: 295px;
  margin: auto;
  margin-top: 8px;
}

.ui.teal.buttons {
  background-color: rgb(84, 110, 122);
}

.teal {
  background-color: rgb(84, 110, 122);
}

.registroUsuario {
  display: block !important;
  color: rgb(84, 110, 122);
  margin-top: 15px;
}
.ui.teal.button:focus,
.ui.teal.buttons .button:focus {
  background-color: rgb(84, 110, 122);
}
.teal.button:focus,
.teal.buttons .button:focus {
  background-color: rgb(84, 110, 122);
}

.camara {
  padding-left: 55px;
}
.tituloImagen {
  color: rgb(84, 110, 122);
  padding-left: 27px;
}
.tituloPortada {
  color: rgb(84, 110, 122);
  padding-left: 5px;
}
.container {
  align-content: center;
  align-items: center;
  min-width: 120px;

  margin: 0px auto;
  padding-left: 8px;
}

.dropzone {
  height: 30px;
  min-width: 100px;
  display: grid !important;
  place-content: center;
  margin: 5px;
  border: solid 1px rgb(182, 178, 178);
}

.dropzone.label {
  margin: 5px;
}

.isActive {
  background-color: #282c34;
  color: white;
}

.thumb {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  max-width: 180px;
  max-height: 250px;
  padding: 4;
  box-sizing: border-box;
}

.thumb-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16 auto;
}

.thumb-inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
  margin: 0 auto;
}

.img {
  display: block;
  width: 98%;
}

.hidden {
  display: none;
}

.buttonImage {
  display: block;
  padding: 5px 10px;
  background-color: #667186;
  color: white;
  border-radius: 5px;
  outline: none;
  border: none;
  width: 100%;
  font-size: 1rem;
  margin: 1rem 0;
  cursor: pointer;
}

.buttonImage:focus {
  outline: none;
  border: none;
}

.buttonImage:hover {
  opacity: 0.7;
}
