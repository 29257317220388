
  .ButonMe2 {
    background-color: #ffffff;
    height: 5vh;
    width: 6vh;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    color: rgb(68, 66, 66);
  }


  .General {
    background-color: #ffffff;
    height: 7vh;
    width: 16vh;
    padding: 1px;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    color: rgb(68, 66, 66);
  }