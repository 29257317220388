.InfoDerecha {
    background-color: #ffffff;
    
    padding: 1px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    color:  rgb(84, 110, 122);
  }